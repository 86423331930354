import { GetStaticPaths, GetStaticProps } from 'next/types';
import React from 'react';
import { RefurbishedDeviceCountryDataContext } from 'src/contexts/RefurbishedDeviceCountryDataContext';
import { getEPPromotions } from 'src/queries/elasticpath/getEPPromotions';
import { getCommonProviderData, WithCommonProviderData } from 'src/queries/groq/commonProviderData';
import { getEmergencyBanner } from 'src/queries/groq/emergencyBanner';
import {
  getRefurbishedDeviceCountryData,
  WithRefurbishedDeviceCountryData,
} from 'src/queries/groq/getRefurbishedDeviceCountryData';
import { getPromotionBundles, WithSanityPromotionBundles } from 'src/queries/groq/productPromotionBundle';
import { getSanityPromotionBanners } from 'src/queries/groq/promotionBanners';
import { getPressPublicationsData } from 'src/queries/groq/publicationsData';
import { getSanityWebsiteContentMainPage } from 'src/queries/groq/sanityWebsiteContent';
import { ExperimentProvider } from 'src/services/experiments/ExperimentContext';
import {
  applyExperimentTracking,
  ExperimentProps,
  getStaticPathsExperiments,
  getStaticPropsExperiments,
} from 'src/services/experiments/optimize';
import { combinePromises } from 'src/utils/combinePromises';
import ConnectedMainPage, { MainPageViewProps } from 'src/views/MainPage';
import { EPPromoContext, WithEpPromos } from 'src/views/MPF/hooks/useElasticPathPromo';
import { PromotionBundleProvider } from 'src/views/MPF/PromotionBundleContext';

type Props = WithCommonProviderData &
  MainPageViewProps &
  WithEpPromos &
  WithRefurbishedDeviceCountryData &
  WithSanityPromotionBundles &
  ExperimentProps;

export const getStaticPaths: GetStaticPaths = async () => {
  const experimentPaths = getStaticPathsExperiments('MP_20230810_MPPP_01');

  return {
    paths: experimentPaths,
    fallback: false,
  };
};

export const getStaticProps: GetStaticProps<Props> = async (context) => {
  const commonProviderData = getCommonProviderData();
  const epPromos = getEPPromotions();
  const sectionData = getSanityWebsiteContentMainPage();
  const emergencyBanner = getEmergencyBanner();
  const promotionBanners = getSanityPromotionBanners();
  const pressPublicationsData = getPressPublicationsData();
  const refurbishedDeviceCountryData = getRefurbishedDeviceCountryData();
  const sanityPromotionBundles = getPromotionBundles();

  const promises = {
    commonProviderData,
    epPromos,
    sectionData,
    promotionBanners,
    emergencyBanner,
    pressPublicationsData,
    refurbishedDeviceCountryData,
    sanityPromotionBundles,
  };
  const fetchedData = await combinePromises(promises);
  return {
    props: {
      commonProviderData: fetchedData.commonProviderData,
      epPromos: fetchedData.epPromos,
      refurbishedDeviceCountryData: fetchedData.refurbishedDeviceCountryData,
      sanityPromotionBundles: fetchedData.sanityPromotionBundles,
      data: {
        sectionData: fetchedData.sectionData,
        promotionBanners: fetchedData.promotionBanners,
        emergencyBanner: fetchedData.emergencyBanner,
        pressPublicationsData: fetchedData.pressPublicationsData,
      },
      ...getStaticPropsExperiments(context),
    },
  };
};

const MainPage: React.FC<Props> = (props) => {
  applyExperimentTracking(props.experimentId);

  return (
    <ExperimentProvider experimentId={props.experimentId} experimentVariantId={props.experimentVariantId}>
      <RefurbishedDeviceCountryDataContext.Provider value={props.refurbishedDeviceCountryData}>
        <EPPromoContext.Provider value={props.epPromos}>
          <PromotionBundleProvider bundles={props.sanityPromotionBundles}>
            <ConnectedMainPage {...props} />
          </PromotionBundleProvider>
        </EPPromoContext.Provider>
      </RefurbishedDeviceCountryDataContext.Provider>
    </ExperimentProvider>
  );
};

export default MainPage;
